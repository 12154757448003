import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'

import { DesktopFlex, MobileFlex } from '../../breakpoints'

const Percentage = styled.h3`
  font-weight: bold;
  font-size: 48px;
  line-height: 70px;
  letter-spacing: -0.04em;
  color: #ffffff;
`
const PercentageTitle = styled.h4`
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
  text-transform: none;
`
const PercentageSubtitle = styled.h5`
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #cbaaff;
`

const PercentageTagline = styled.h6`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ff547d;
`
const TextFirstNumber = styled(Flex)`
  @media (min-width: 512px) {
    right: 195px;
  }
`

const SalesFirstNumber = props => {
  const { rectangle, rectangleMobile } = useStaticQuery(
    graphql`
      query {
        rectangle: file(
          relativePath: { eq: "use_cases/marketing/Rectangle1.png" }
        ) {
          childImageSharp {
            fixed(width: 317, height: 206) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        rectangleMobile: file(
          relativePath: { eq: "use_cases/marketing/RectangleMobile.png" }
        ) {
          childImageSharp {
            fixed(width: 277, height: 213) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return (
    <>
      <Flex
        css={{ position: 'relative' }}
        justifyContent={['center', 'flex-end']}
        pr={[0, '10%']}
        mb={[-50, 0]}
        pl={[40, 0]}
      >
        <DesktopFlex>
          <Img
            style={{ position: 'absolute', top: '-164px', right: '165px' }}
            fixed={rectangle.childImageSharp.fixed}
          />
        </DesktopFlex>
        <MobileFlex>
          <Img
            style={{ position: 'absolute', top: '-164px', right: '40px' }}
            fixed={rectangleMobile.childImageSharp.fixed}
          />
        </MobileFlex>
        <TextFirstNumber
          width={[0.8, 270]}
          py={20}
          px={20}
          css={{ position: 'absolute', top: -150 }}
        >
          <Flex flexDirection="column">
            <Flex css={{ marginBottom: -40 }} alignItems="center">
              <Percentage style={{ marginRight: 9 }}>
                {props.number.number}
              </Percentage>
              <PercentageTitle>{props.number.title}</PercentageTitle>
            </Flex>
            <PercentageSubtitle style={{ marginBottom: 10 }}>
              {props.number.subtitle}
            </PercentageSubtitle>
            <PercentageTagline>{props.number.tagline}</PercentageTagline>
          </Flex>
        </TextFirstNumber>
      </Flex>
    </>
  )
}

export default SalesFirstNumber
