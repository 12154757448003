import React from 'react'
import ConsumerTriangle from '../../../static/img/use_cases/sales/consumerTriangleSales.svg'
import ItsTimeTriangleMobile from '../../../static/img/use_cases/cs/ItsTimeTriangleMobile.svg'
import ContactTriangle from '../../../static/img/use_cases/sales/salesContactTriangle.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'

const SalesFigures = props => {
  return (
    <>
      <DesktopFlex>
        <ContactTriangle
          style={{ position: 'absolute', top: '-66%', right: '50%' }}
        />
        <ConsumerTriangle
          style={{ position: 'absolute', top: '81%', right: '53%' }}
        />
      </DesktopFlex>
    </>
  )
}

const SalesFiguresMobile = props => {
  return (
    <MobileFlex>
      <ItsTimeTriangleMobile
        style={{ position: 'absolute', top: '90%', right: 0 }}
      />
    </MobileFlex>
  )
}

export { SalesFigures, SalesFiguresMobile }
