import React, { useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import * as EmailValidator from 'email-validator'
import { Flex } from '@rebass/grid'

import Section from '../Section'
import { OutlineButton } from '../buttons'
import { Selector, RadioInput, MessageBox } from '../forms'

const Title = styled.h2`
  font-size: 30px;
  line-height: 40px;
  color: #6c21e4;
  width: 300px;
  @media (min-width: 512px) {
    width: 414px;
  }
`
const Subtitle = styled.h3`
  font-size: 18px;
  line-height: 26px;
  width: 350px;
  color: #696973;
`
const TextStyle = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  color: #333333;
`
const CheckboxText = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  color: #333333;
  margin-top: 14px;
  @media (min-width: 512px) {
    margin-top: 14px;
    margin-left: -5px;
  }
`
const SubText = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin-top: -20px;
  color: #696973;
`
const FeedbackTitle = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 132.19%;
  color: #333333;
  padding-bottom: 5px;
  padding-top: 30px;
`

const FeedbackContent = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 15px;
  color: #696973;
`

const Connect = props => {
  const [value, setValue] = useState('')
  function handleChangeValue(event) {
    setValue(event.target.value)
  }
  const [isSend, setIsSend] = useState(undefined)
  const [emailCorrect, setEmailCorrect] = useState(true)
  const handleChangeData = name => event => {
    updateData({ ...userData, [name]: event.target.value })
  }
  let radioInput = {
    title: props.section.question_yes_no,
    option_1: 'Yes',
    option_2: 'No',
  }
  const [userData, updateData] = useState({
    comesFrom: 'web',
    slug: props.slug,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    title: '',
    traffic: '',
    company: '',
    country: '',
    trafficVolume: '',
    whatsapp: 'false',
    twitter: 'false',
    viber: 'false',
    mobileChat: 'false',
    facebook: 'false',
    webchat: 'false',
    line: 'false',
    telegram: 'false',
    agree: 'false',
    other: 'false',
    message: '',
    trafficVolumeOptions: [
      { traffic_volume_1: 'Less than 2k messages' },
      { traffic_volume_2: '2k to 5k messages' },
      { traffic_volume_3: '5k to 10k messages' },
      { traffic_volume_4: '10k to 100k messages' },
      { traffic_volume_5: '100k to 500k messages' },
      { traffic_volume_6: '500k to 2 million messages' },
      { traffic_volume_7: '2 million messages or more' },
    ],
    supportPeople: '',
    supportPeopleOptions: [
      { support_people_1: '1-2' },
      { support_people_2: '3-10' },
      { support_people_3: '11-25' },
      { support_people_4: '26-50' },
      { support_people_5: 'More than 50' },
    ],
  })
  let dictionary = {
    traffic_volume_1: '1',
    traffic_volume_2: '2000',
    traffic_volume_3: '5000',
    traffic_volume_4: '10000',
    traffic_volume_5: '100000',
    traffic_volume_6: '500000',
    traffic_volume_7: '2000000',
    est_mau_1: '1',
    est_mau_2: '1000',
    est_mau_3: '5000',
    est_mau_4: '10000',
    est_mau_5: '20000',
    support_people_1: '1',
    support_people_2: '3',
    support_people_3: '11',
    support_people_4: '26',
    support_people_5: '50',
    use_case_1: 'Customer Service',
    use_case_2: 'Marketing',
    use_case_3: 'Sales',
    use_case_4: 'Other',
  }

  const searchLabel = (property, dictionary) => {
    if (dictionary[property]) {
      return dictionary[property]
    } else {
      return ''
    }
  }
  const validateData = userData => {
    let propertiesRequired = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      phone: userData.phone,
      title: userData.title,
      traffic: userData.traffic,
      company: userData.company,
      country: userData.country,
      trafficVolume: searchLabel(userData.trafficVolume, dictionary),
      supportPeople: searchLabel(userData.supportPeople, dictionary),
    }
    let propertiesNotRequired = {
      whatsapp: userData.whatsapp,
      twitter: userData.twitter,
      viber: userData.viber,
      mobileChatSdk: userData.mobileChat,
      facebook: userData.facebook,
      webchat: userData.webchat,
      line: userData.line,
      telegram: userData.telegram,
      message: userData.message,
      slug: userData.slug,
    }

    let properties = { ...propertiesRequired, ...propertiesNotRequired }
    if (
      Object.values(propertiesRequired).some(element => element == '') &&
      !userData.agree
    ) {
      setIsSend(false)
    } else {
      if (
        userData.email.includes('mail.') ||
        !EmailValidator.validate(userData.email)
      ) {
        setIsSend(false)
        setEmailCorrect(false)
      } else {
        setIsSend(true)
        sendClick(properties)
      }
    }
  }
  const sendClick = properties => {
    if (typeof window !== 'undefined') {
      setEmailCorrect(true)
      window.analytics.identify(properties)
      window.analytics.track('useCase Form', properties)
      let {
        email: Email,
        firstName: FirstName,
        lastName: LastName,
        ...custom
      } = properties
      window.Autopilot.run('associate', {
        Email,
        FirstName,
        LastName,
        custom,
      })
      updateData({
        comesFrom: 'web',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        title: '',
        traffic: '',
        company: '',
        country: '',
        whatsapp: 'false',
        twitter: 'false',
        viber: 'false',
        mobileChat: 'false',
        facebook: 'false',
        webchat: 'false',
        line: 'false',
        telegram: 'false',
        agree: 'false',
        other: 'false',
        traffic: '',
        trafficVolume: '',
        message: '',
        trafficVolumeOptions: [
          { traffic_volume_1: 'Less than 2k messages' },
          { traffic_volume_2: '2k to 5k messages' },
          { traffic_volume_3: '5k to 10k messages' },
          { traffic_volume_4: '10k to 100k messages' },
          { traffic_volume_5: '100k to 500k messages' },
          { traffic_volume_6: '500k to 2 million messages' },
          { traffic_volume_7: '2 million messages or more' },
        ],
        supportPeople: '',
        supportPeopleOptions: [
          { support_people_1: '1-2' },
          { support_people_2: '3-10' },
          { support_people_3: '11-25' },
          { support_people_4: '26-50' },
          { support_people_5: 'More than 50' },
        ],
      })
    }
  }

  const Feedback = props => {
    if (isSend == true) {
      return (
        <>
          <FeedbackTitle>Success! Message sent.</FeedbackTitle>
          <FeedbackContent>
            A team member will reach out to you within one business day.
          </FeedbackContent>
        </>
      )
    } else if (isSend == false) {
      return (
        <FeedbackContent>Please enter all required fields.</FeedbackContent>
      )
    } else {
      return <></>
    }
  }

  return (
    <Section px={[15, 0]} pb={[100, 130]} pt={[100, 120]}>
      <Title>{props.section.title}</Title>
      <Subtitle>{props.section.subtitle}</Subtitle>
      <Flex ml={[0, -20]} flexDirection={['column', 'row']}>
        <Flex px={[0, 20]} justifyContent="center" width={[1, 1 / 3]}>
          <TextField
            label={props.section.form_field1}
            name="firstname"
            value={userData.firstName}
            onChange={handleChangeData('firstName')}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </Flex>
        <Flex justifyContent="center" px={[0, 20]} width={[1, 1 / 3]}>
          <TextField
            label={props.section.form_field2}
            name="lastname"
            value={userData.lastName}
            onChange={handleChangeData('lastName')}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </Flex>
        <Flex justifyContent="center" px={[0, 20]} width={[1, 1 / 3]}>
          <TextField
            label={props.section.form_field3}
            name="email"
            value={userData.email}
            onChange={handleChangeData('email')}
            fullWidth
            autoComplete="email"
            margin="normal"
            variant="outlined"
          />
        </Flex>
      </Flex>
      <Flex ml={[0, -20]} flexDirection={['column', 'row']}>
        <Flex px={[0, 20]} justifyContent="center" width={[1, 1 / 3]}>
          <TextField
            label={props.section.form_field4}
            name="phone"
            value={userData.phone}
            onChange={handleChangeData('phone')}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </Flex>
        <Flex justifyContent="center" px={[0, 20]} width={[1, 1 / 3]}>
          <TextField
            label={props.section.form_field5}
            name="title"
            value={userData.title}
            onChange={handleChangeData('title')}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </Flex>
        <Flex justifyContent="center" px={[0, 20]} width={[1, 1 / 3]}>
          <TextField
            label={props.section.form_field6}
            name="company"
            value={userData.company}
            onChange={handleChangeData('company')}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </Flex>
      </Flex>
      <Flex ml={[0, -20]} flexDirection={['column', 'row']}>
        <Flex px={[0, 20]} justifyContent="center" width={[1, 1 / 3]}>
          <TextField
            label={props.section.form_field7}
            name="country"
            value={userData.country}
            onChange={handleChangeData('country')}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </Flex>
      </Flex>

      <Flex flexDirection={['column', 'row']}>
        <Flex pt={[40, 50]} flexDirection="column" width={[1, 1 / 3]}>
          <TextStyle>{props.section.messaging_channel_title}</TextStyle>
          <Flex flexDirection="row">
            <Flex ml={-10} flexDirection="column" width={[1, 1 / 2]}>
              {Object.keys(props.section.checkbox_left).map((option, i) => {
                return (
                  <Flex key={i}>
                    <Checkbox
                      style={{ width: 45 }}
                      color="default"
                      onChange={handleChangeData(option)}
                      value={userData[option] == 'true' ? false : true}
                    />
                    <CheckboxText>
                      {props.section.checkbox_left[option]}
                    </CheckboxText>
                  </Flex>
                )
              })}
            </Flex>
            <Flex ml={-10} flexDirection="column" width={[1, 1 / 2]}>
              {Object.keys(props.section.checkbox_right).map((option, i) => {
                return (
                  <Flex key={i}>
                    <Checkbox
                      style={{ width: 45 }}
                      color="default"
                      onChange={handleChangeData(option)}
                      value={userData[option] == 'true' ? false : true}
                    />
                    <CheckboxText>
                      {props.section.checkbox_right[option]}
                    </CheckboxText>
                  </Flex>
                )
              })}
            </Flex>
          </Flex>
        </Flex>

        <Flex px={[0, 20]} pt={50} flexDirection="column" width={[1, 1 / 3]}>
          <Flex mt={[-10, 0]}>
            <FormControl>
              <Flex my={[10, 20]} flexDirection="column" width={[1, '110%']}>
                <RadioInput
                  style={{ marginLeft: -15, marginTop: -22 }}
                  radio={radioInput}
                  value={userData.traffic}
                  onChange={handleChangeData('traffic')}
                />
              </Flex>
            </FormControl>
          </Flex>
          <TextStyle>{props.section.question_traffic_per_month}</TextStyle>
          <SubText>{props.section.traffic_explanation}</SubText>
          <Selector
            item="selector1"
            label="Choose one"
            value={userData.trafficVolume}
            onChange={handleChangeData('trafficVolume')}
            options={userData.trafficVolumeOptions}
            identifier={'traffic_volume'}
          />
        </Flex>
        <Flex
          px={[0, 20]}
          pt={50}
          pb={[50, 0]}
          flexDirection="column"
          width={[1, 1 / 3]}
        >
          <TextStyle>{props.section.question_supporting_clients}</TextStyle>
          <Selector
            item="selector2"
            label="Choose one"
            value={userData.supportPeople}
            onChange={handleChangeData('supportPeople')}
            options={userData.supportPeopleOptions}
            identifier={'support_people'}
          />
        </Flex>
      </Flex>
      <Flex pt={[0, 50]} width={[1, 730]}>
        <MessageBox
          label="Message"
          onChange={handleChangeData('message')}
          value={userData.message}
        />
      </Flex>
      <Flex ml={[0, -13]}>
        <Checkbox
          style={{ width: 45 }}
          color="default"
          onChange={handleChangeData('agree')}
          value="agree"
        />
        <CheckboxText>{props.section.terms_agree}</CheckboxText>
      </Flex>
      <OutlineButton
        color="white"
        hoverBackground="#FF4B76"
        style={{
          background: '#FF2B5E',
          height: '40px',
          width: '140px',
          marginTop: '30px',
        }}
        onClick={() => validateData(userData)}
      >
        SEND
      </OutlineButton>
      <Feedback />
    </Section>
  )
}

export default Connect
