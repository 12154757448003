import React from 'react'
import { Flex, Box } from '@rebass/grid'
import Section from '../Section'
import styled from 'styled-components'
import CFactor1 from '../../static/img/use_cases/cs/Factor1.svg'
import CFactor2 from '../../static/img/use_cases/cs/Factor2.svg'
import CFactor3 from '../../static/img/use_cases/cs/Factor3.svg'
import CFactor4 from '../../static/img/use_cases/cs/Factor4.svg'
import CFactor5 from '../../static/img/use_cases/cs/Factor5.svg'
import CFactor6 from '../../static/img/use_cases/cs/Factor6.svg'
import CFactor7 from '../../static/img/use_cases/cs/Factor7.svg'
import CFactor8 from '../../static/img/use_cases/cs/Factor8.svg'
import CFactor9 from '../../static/img/use_cases/cs/Factor9.svg'
import MFactor1 from '../../static/img/use_cases/marketing/Factor1.svg'
import MFactor2 from '../../static/img/use_cases/marketing/Factor2.svg'
import MFactor3 from '../../static/img/use_cases/marketing/Factor3.svg'
import MFactor4 from '../../static/img/use_cases/marketing/Factor4.svg'
import MFactor5 from '../../static/img/use_cases/marketing/Factor5.svg'
import MFactor6 from '../../static/img/use_cases/marketing/Factor6.svg'
import MFactor7 from '../../static/img/use_cases/marketing/Factor7.svg'
import MFactor8 from '../../static/img/use_cases/marketing/Factor8.svg'
import MFactor9 from '../../static/img/use_cases/marketing/Factor9.svg'
import SFactor1 from '../../static/img/use_cases/sales/Factor1.svg'
import SFactor2 from '../../static/img/use_cases/sales/Factor2.svg'
import SFactor3 from '../../static/img/use_cases/sales/Factor3.svg'
import SFactor4 from '../../static/img/use_cases/sales/Factor4.svg'
import SFactor5 from '../../static/img/use_cases/sales/Factor5.svg'
import SFactor6 from '../../static/img/use_cases/sales/Factor6.svg'
import SFactor7 from '../../static/img/use_cases/sales/Factor7.svg'
import SFactor8 from '../../static/img/use_cases/sales/Factor8.svg'
import SFactor9 from '../../static/img/use_cases/sales/Factor9.svg'
import { DesktopFlex } from '../breakpoints'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #FFFFFF;
`

const Title = styled.h3`
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  align-self: center;
  color: #333333;
  width: 100%;
  @media (min-width: 512px) {
    width: 40%;
  }
`

const PointTitle = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #333333;
  margin-bottom: 16px;
  text-transform: none;
`

const PointDescription = styled.h5`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #696973;
`

const Points = props => {
  let points = props.points
  if (props.template === 'UseCaseCustomerService') {
    Object.assign(points.point_1, { icon: <CFactor1 /> })
    Object.assign(points.point_2, { icon: <CFactor2 /> })
    Object.assign(points.point_3, { icon: <CFactor3 /> })
    Object.assign(points.point_4, { icon: <CFactor4 /> })
    Object.assign(points.point_5, { icon: <CFactor5 /> })
    Object.assign(points.point_6, { icon: <CFactor6 /> })
    Object.assign(points.point_7, { icon: <CFactor7 /> })
    Object.assign(points.point_8, { icon: <CFactor8 /> })
    Object.assign(points.point_9, { icon: <CFactor9 /> })
  }
  if (props.template === 'UseCaseMarketing') {
    Object.assign(points.point_1, { icon: <MFactor1 /> })
    Object.assign(points.point_2, { icon: <MFactor2 /> })
    Object.assign(points.point_3, { icon: <MFactor3 /> })
    Object.assign(points.point_4, { icon: <MFactor4 /> })
    Object.assign(points.point_5, { icon: <MFactor5 /> })
    Object.assign(points.point_6, { icon: <MFactor6 /> })
    Object.assign(points.point_7, { icon: <MFactor7 /> })
    Object.assign(points.point_8, { icon: <MFactor8 /> })
    Object.assign(points.point_9, { icon: <MFactor9 /> })
  }
  if (props.template === 'UseCaseSales') {
    Object.assign(points.point_1, { icon: <SFactor1 /> })
    Object.assign(points.point_2, { icon: <SFactor2 /> })
    Object.assign(points.point_3, { icon: <SFactor3 /> })
    Object.assign(points.point_4, { icon: <SFactor4 /> })
    Object.assign(points.point_5, { icon: <SFactor5 /> })
    Object.assign(points.point_6, { icon: <SFactor6 /> })
    Object.assign(points.point_7, { icon: <SFactor7 /> })
    Object.assign(points.point_8, { icon: <SFactor8 /> })
    Object.assign(points.point_9, { icon: <SFactor9 /> })
  }

  return (
    <Flex pt={[49, 20]} flexWrap="wrap">
      {Object.entries(points).map(([k, point], i) => (
        <Flex
          width={[1, 1 / 3]}
          px={[0, 40]}
          pb={[0, 55]}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          key={i}
        >
          <Flex justifyContent="center" py={10}>
            {point.icon}
          </Flex>
          <Flex flexDirection="column" py={10} css={{ height: 130 }}>
            <PointTitle>{point.title}</PointTitle>
            <PointDescription>{point.description}</PointDescription>
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}
// * By this moment, the Factors slider (mobile) is not implemented.
// * As suggested by marketing team, we should not show them in mobile by this moment.
const Factors = props => {
  let paddingBottom = 0
  if (props.pageContext.template == 'UseCaseSales') {
    paddingBottom = 130
  }
  return (
    <DesktopFlex flexDirection="column" css={{ position: 'relative' }}>
      <Section
        px={[15, 0]}
        pb={[20, paddingBottom]}
        background={<Bg />}
        pt={73}
      >
        <Flex justifyContent="center" flexDirection="column">
          <Title>{props.section.title}</Title>
          <Points
            template={props.pageContext.template}
            points={props.section.points}
          />
        </Flex>
      </Section>
    </DesktopFlex>
  )
}

export default Factors
