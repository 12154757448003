import React from 'react'
import { Flex, Box } from '@rebass/grid'
import Rectangle from '../../../static/img/use_cases/cs/Rectangle.svg'
import RectangleTriangle from '../../../static/img/use_cases/cs/RectangleTriangle.svg'
import RectangleMobile from '../../../static/img/use_cases/cs/RectangleMobile.svg'
import RectangleTriangleMobile from '../../../static/img/use_cases/cs/RectangleTriangleMobile.svg'
import DescBgTriangle from '../../../static/img/use_cases/cs/DescBgTriangle.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'
import Img from 'gatsby-image'

const CustomerServiceFigures = props => {
  return (
    <>
      <DesktopFlex>
        <DescBgTriangle
          style={{
            position: 'absolute',
            top: 110,
            zIndex: 2,
            left: '48%',
          }}
        />
      </DesktopFlex>
      <DesktopFlex
        style={{
          position: 'absolute',
          top: 73,
          right: 0,
        }}
      >
        <Flex>
          {props.img && (
            <Img
              fluid={props.img.localFile.childImageSharp.fluid}
              style={{
                position: 'absolute',
                top: 112,
                left: -80,
                width: 320,
                height: 500,
                zIndex: 1,
              }}
            />
          )}
          <RectangleTriangle
            style={{
              position: 'absolute',
              zIndex: 1,
              right: '15%',
              top: '33%',
            }}
          />
          <Rectangle style={{ position: 'relative' }} />
        </Flex>
      </DesktopFlex>
    </>
  )
}

const CustomerServiceFiguresMobile = props => {
  let narrowScreen = '0px'
  if (typeof window !== 'undefined') {
    if (screen.width < 350) {
      narrowScreen = `100px`
    }
  }
  return (
    <>
      <MobileFlex
        style={{
          position: 'absolute',
          top: `calc(52% + ${narrowScreen})`,
          right: 0,
        }}
      >
        <Flex>
          <RectangleTriangleMobile
            style={{
              position: 'absolute',
              top: 225,
              right: 0,
              zIndex: 1,
            }}
          />
          <RectangleMobile
            style={{ position: 'absolute', top: -86, right: 0, zIndex: 0 }}
          />
        </Flex>
      </MobileFlex>
      <MobileFlex px={15}>
        {props.img && (
          <Img
            fluid={props.img.localFile.childImageSharp.fluid}
            style={{
              position: 'absolute',
              top: '44%',
              zIndex: 2,
              width: 303,
              height: 473,
            }}
          />
        )}
      </MobileFlex>
    </>
  )
}

export { CustomerServiceFigures, CustomerServiceFiguresMobile }
