import React from 'react'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import InformationCase from '../../static/img/use_cases/cs/InformationCase.svg'
import InformationCaseSales from '../../static/img/use_cases/sales/RectangleSales.svg'
import InformationCaseMobile from '../../static/img/use_cases/cs/InformationCaseMobile.svg'
import TriangleCS from '../../static/img/use_cases/cs/firstTriangleCS.svg'

const InformationTagline = styled.h5`
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #ffffff;
  position: absolute;
  z-index: 2;
`
const InformationTaglineMobile = styled.h5`
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #ffffff;
  position: absolute;
  z-index: 2;
  padding-left: 25%;
  padding-right: 15px;
  top: -30px;
  @media (min-width: 512px) {
    width: 30%;
    right: 10%;
    top: -50px;
  }
`

const informationStyle = {
  position: 'absolute',
  zIndex: 1,
  top: -110,
  right: 0,
}

const informationMobileStyle = {
  position: 'absolute',
  zIndex: 1,
  top: -80,
  right: 0,
}

const Information = props => {
  return (
    <>
      {props.pageContext.template == 'UseCaseCustomerService' && (
        <TriangleCS style={{ position: 'absolute', top: 538, left: 930 }} />
      )}
      <DesktopFlex style={{ position: 'relative' }}>
        {props.pageContext.template == 'UseCaseSales' && (
          <>
            <InformationCaseSales style={informationStyle} />
            <Flex>
              <InformationTagline
                style={{
                  width: 395,
                  right: 170,
                  top: -50,
                  paddingLeft: 30,
                }}
              >
                {props.information.tagline}
              </InformationTagline>
            </Flex>
          </>
        )}
      </DesktopFlex>
      {props.pageContext.template == 'UseCaseCustomerService' && (
        <>
          <DesktopFlex>
            <InformationCase
              style={informationStyle}
              style={{ top: 1050, right: 0, position: 'absolute' }}
            />
            <Flex>
              <InformationTagline
                style={{
                  position: 'absolute',
                  top: 1105,
                  width: 395,
                  right: 170,
                }}
              >
                {props.information.tagline}
              </InformationTagline>
            </Flex>
          </DesktopFlex>
          <MobileFlex>
            <InformationCaseMobile
              style={informationStyle}
              style={{ top: 2032, right: 0, position: 'absolute' }}
            />
            <Flex width={1} height={1}>
              <InformationTagline style={{ top: 2094, width: 252, right: 15 }}>
                {props.information.tagline}
              </InformationTagline>
            </Flex>
          </MobileFlex>
        </>
      )}
    </>
  )
}

export default Information
