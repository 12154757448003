import React from 'react'
import { Flex, Box } from '@rebass/grid'
import Section from '../Section'
import styled from 'styled-components'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import {
  CustomerServiceFigures,
  CustomerServiceFiguresMobile,
} from './cs/number_story_figures'
import {
  MarketingFigures,
  MarketingFiguresMobile,
} from './marketing/number_story_figures'
import { SalesFigures, SalesFiguresMobile } from './sales/number_story_figures'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #362745;
`

const Title = styled.h3`
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
`
const Number = styled.h3`
  font-weight: bold;
  font-size: 64px;
  line-height: 94px;
  letter-spacing: -0.04em;
  color: #cbaaff;
`
const NumberTitle = styled.h4`
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  color: #cbaaff;
  text-transform: none;
`
const NumberSubtitle = styled.h5`
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #ffffff;
`
const Company = styled.h6`
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  color: #ff547d;
`

const Separator = styled(Flex)`
  width: 100%;
  height: 0px;
  border: 0.5px solid #5d4477;
  margin-bottom: 10px;
  @media (min-width: 512px) {
    margin-bottom: 0px;
    width: 90%;
    border: 1px solid #5d4477;
  }
`

const StyledFlex = styled(Flex)`
  height: 250px;
  @media (max-width: 512px) {
    height: auto;
  }
`

const Story = props => (
  <Flex width={1} flexDirection="column" mr={10}>
    <DesktopFlex>
      <Separator />
    </DesktopFlex>
    <MobileFlex>{props.parity % 2 == 0 && <Separator />}</MobileFlex>
    <StyledFlex flexDirection="column" width={1} pr={[0, 80]} pt={[0, 20]}>
      <Flex css={{ marginBottom: -10 }} alignItems="flex-end">
        <Number
          style={{
            marginTop: 2,
            marginBottom: 9,
            marginRight: 9,
          }}
        >
          {props.number.number}
        </Number>
        <NumberTitle>{props.number.title}</NumberTitle>
      </Flex>
      <NumberSubtitle style={{ marginBottom: 20 }}>
        {props.number.subtitle}
      </NumberSubtitle>
      <Company>{props.number.tagline}</Company>
    </StyledFlex>
    <DesktopFlex>
      <Separator style={{ marginTop: -20 }} />
    </DesktopFlex>
    <MobileFlex>{props.parity % 2 == 0 && <Separator />}</MobileFlex>
  </Flex>
)

const NumberStory = props => {
  let paddingTopMobile = 70
  if (props.pageContext.template == 'UseCaseMarketing') {
    paddingTopMobile = 190
  }
  return (
    <>
      <Section
        px={[15, 0]}
        background={<Bg />}
        pt={[paddingTopMobile, 90]}
        pb={[80, 132]}
      >
        <Flex flexDirection={'column'} width={1}>
          <Title>{props.section.title}</Title>
          <Flex flexDirection={['column', 'row']}>
            {Object.entries(props.section.numbers)
              .filter(([k, number]) => number.number !== '')
              .map(([k, number], i) => (
                <Story key={i} number={number} parity={i} />
              ))}
          </Flex>
        </Flex>
        <DesktopFlex>
          {props.pageContext.template === 'UseCaseCustomerService' && (
            <CustomerServiceFigures img={props.section.img} />
          )}
          {props.pageContext.template === 'UseCaseMarketing' && (
            <MarketingFigures img={props.section.img} />
          )}
          {props.pageContext.template === 'UseCaseSales' && <SalesFigures />}
        </DesktopFlex>
        <MobileFlex>
          {props.pageContext.template === 'UseCaseCustomerService' && (
            <CustomerServiceFiguresMobile img={props.section.img_mobile} />
          )}
          {props.pageContext.template === 'UseCaseMarketing' && (
            <MarketingFiguresMobile img={props.section.img_mobile} />
          )}
          {props.pageContext.template === 'UseCaseSales' && (
            <SalesFiguresMobile img={props.section.img_mobile} />
          )}
        </MobileFlex>
      </Section>
    </>
  )
}

export default NumberStory
