import React from 'react'
import { BreakpointProvider } from 'react-socks'
import { graphql } from 'gatsby'

import CaseDescription from '../components/use_cases/case_description'
import Connect from '../components/use_cases/connect'
import Contact from '../components/use_cases/contact'
import Conversations from '../components/use_cases/conversations'
import CustomerServiceNumber from '../components/use_cases/cs/customer_service_number'
import Factors from '../components/use_cases/factors'
import Head from '../components/head'
import HubtypePoints from '../components/use_cases/hubtype_points'
import Information from '../components/use_cases/information'
import Integrate from '../components/use_cases/integrate'
import ItsTime from '../components/use_cases/its_time'
import MarketingFirstNumber from '../components/use_cases/marketing/marketing_first_number'
import MarketingSecondNumber from '../components/use_cases/marketing/marketing_second_number'
import NumberStory from '../components/use_cases/number_story'
import Purchase from '../components/use_cases/purchase'
import Reasons from '../components/use_cases/reasons'
import SalesFirstNumber from '../components/use_cases/sales/sales_first_number'
import SalesSecondNumber from '../components/use_cases/sales/sales_second_number'
import UseCasesContainer from '../components/use_cases/use_cases_container'

import '../styles/main.scss'

const UseCases = ({ data, pageContext }) => {
  const { wordpressPage: page } = data
  const useCase = page.polylang_translations.filter(
    e => e.polylang_current_lang === pageContext.lang
  )[0]

  let {
    acf: {
      case_description,
      hubtype_points,
      information,
      reasons,
      factors,
      numbers: { number_1, number_2 },
      contact,
      connect,
      number_story,
      integrate,
      its_time,
      message_conversation,
      not_ready_to_talk,
      purchase,
    },
    yoast_meta,
  } = useCase
  let props = { pageContext }
  return (
    <BreakpointProvider style={{ marginBottom: -10 }}>
      <Head
        alternates={pageContext.i18n}
        autopilot={pageContext.autopilot}
        description={yoast_meta.yoast_wpseo_metadesc}
        lang={pageContext.lang}
        title={yoast_meta.yoast_wpseo_title}
        uri={pageContext.relativeUrl}
      />
      <UseCasesContainer {...props} section={not_ready_to_talk}>
        <CaseDescription pageContext={pageContext} section={case_description} />
        <HubtypePoints pageContext={pageContext} section={hubtype_points} />

        {pageContext.template === 'UseCaseMarketing' && (
          <MarketingFirstNumber number={number_1} />
        )}
        {pageContext.template === 'UseCaseCustomerService' && (
          <Information pageContext={pageContext} information={information} />
        )}
        {pageContext.template === 'UseCaseSales' && (
          <>
            <SalesFirstNumber number={number_1} />
            <Conversations
              info={message_conversation}
              pageContext={pageContext}
            />
          </>
        )}
        <Reasons pageContext={pageContext} section={reasons} />
        {(pageContext.template === 'UseCaseCustomerService' ||
          pageContext.template === 'UseCaseMarketing') && (
          <Conversations
            info={message_conversation}
            pageContext={pageContext}
          />
        )}
        {pageContext.template === 'UseCaseMarketing' && (
          <>
            <Contact pageContext={pageContext} section={contact} />
            <Integrate pageContext={pageContext} section={integrate} />
          </>
        )}
        {pageContext.template === 'UseCaseSales' && (
          <SalesSecondNumber number={number_2} information={information} />
        )}
        <Factors pageContext={pageContext} section={factors} />
        {pageContext.template === 'UseCaseSales' && (
          <Information pageContext={pageContext} information={information} />
        )}
        {pageContext.template === 'UseCaseCustomerService' && (
          <CustomerServiceNumber number={number_2} />
        )}
        {pageContext.template === 'UseCaseMarketing' && (
          <MarketingSecondNumber number={number_2} />
        )}

        {(pageContext.template === 'UseCaseCustomerService' ||
          pageContext.template === 'UseCaseSales') && (
          <>
            <Contact pageContext={pageContext} section={contact} />
            <NumberStory pageContext={pageContext} section={number_story} />
          </>
        )}
        {pageContext.template === 'UseCaseMarketing' && (
          <Purchase pageContext={pageContext} section={purchase} />
        )}
        {pageContext.template === 'UseCaseCustomerService' && (
          <Integrate pageContext={pageContext} section={integrate} />
        )}
        {(pageContext.template === 'UseCaseCustomerService' ||
          pageContext.template === 'UseCaseMarketing') && (
          <ItsTime pageContext={pageContext} section={its_time} />
        )}
        <Connect
          slug={useCase.slug}
          pageContext={pageContext}
          section={connect}
        />
      </UseCasesContainer>
    </BreakpointProvider>
  )
}

export default UseCases
export const pageQuery = graphql`
  query UseCaseByType($template: String!) {
    wordpressPage(acf: { type: { eq: $template } }) {
      polylang_translations {
        yoast_meta {
          yoast_wpseo_title
          yoast_wpseo_metadesc
        }
        polylang_current_lang
        wordpress_id
        title
        slug
        acf {
          case_description {
            title
            tagline
            description
            img {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1050, quality: 85) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            img_mobile {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1050, quality: 85) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          hubtype_points {
            tagline
            points {
              point_1 {
                title
                description
              }
              point_2 {
                title
                description
              }
              point_3 {
                title
                description
              }
              point_4 {
                title
                description
              }
              point_5 {
                title
                description
              }
              point_6 {
                title
                description
              }
            }
          }
          message_conversation {
            title
            description
          }
          information {
            tagline
          }
          reasons {
            title
            description
            reason_percentage {
              percentage
              title
              subtitle
            }
            title2
            description2
          }
          factors {
            title
            points {
              point_1 {
                title
                description
              }
              point_2 {
                title
                description
              }
              point_3 {
                title
                description
              }
              point_4 {
                title
                description
              }
              point_5 {
                title
                description
              }
              point_6 {
                title
                description
              }
              point_7 {
                title
                description
              }
              point_8 {
                title
                description
              }
              point_9 {
                title
                description
              }
            }
          }
          numbers {
            number_1 {
              number
              title
              subtitle
              tagline
            }
            number_2 {
              number
              title
              subtitle
              tagline
            }
          }
          contact {
            title
            subtitle
            button {
              text
              url
            }
          }
          connect {
            title
            subtitle
            form_field1
            form_field2
            form_field3
            form_field4
            form_field5
            form_field6
            form_field7
            messaging_channel_title
            checkbox_left {
              whatsapp
              twitter
              viber
              mobileChat
              other
            }
            checkbox_right {
              facebook
              telegram
              webchat
              line
            }
            question_yes_no
            question_traffic_per_month
            traffic_explanation
            selector1 {
              option1
              option2
              option3
              option4
              option5
              option6
              option7
            }
            question_supporting_clients
            selector2 {
              option1
              option2
              option3
              option4
              option5
            }
            message
            terms_agree
          }
          purchase {
            title
            purchases {
              purchase1 {
                title
                percentage1
                percentage2
                text1
                text2
              }
              purchase2 {
                title
                percentage1
                percentage2
                text1
                text2
              }
              purchase3 {
                title
                percentage1
                percentage2
                text1
                text2
              }
            }
          }
          number_story {
            title
            numbers {
              number_1 {
                number
                title
                subtitle
                tagline
              }
              number_2 {
                number
                title
                subtitle
                tagline
              }
              number_3 {
                number
                title
                subtitle
                tagline
              }
              number_4 {
                number
                title
                subtitle
                tagline
              }
            }
          }
          integrate {
            title
            description
          }
          its_time {
            title
            description
            cta {
              text
              url
            }
          }
          not_ready_to_talk {
            title
            description
          }
        }
      }
      id
    }
  }
`
