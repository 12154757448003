import React from 'react'
import ItsTimeTriangle from '../../../static/img/use_cases/cs/ItsTimeTriangle.svg'
import ItsTimeTriangleMobile from '../../../static/img/use_cases/cs/ItsTimeTriangleMobile.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'

const CustomerServiceFigures = props => {
  return (
    <>
      <DesktopFlex>
        <ItsTimeTriangle
          style={{ position: 'absolute', top: '450%', left: '35%' }}
        />
      </DesktopFlex>
    </>
  )
}

const CustomerServiceFiguresMobile = props => {
  return (
    <MobileFlex>
      <ItsTimeTriangleMobile
        style={{ position: 'absolute', top: '93%', right: '5%' }}
      />
    </MobileFlex>
  )
}

export { CustomerServiceFigures, CustomerServiceFiguresMobile }
