import React from 'react'
import { Flex, Box } from '@rebass/grid'
import Section from '../Section'
import styled from 'styled-components'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import ReasonsImgSales from '../../static/img/use_cases/sales/reasons_sales.svg'
import ReasonsImgSalesMobile from '../../static/img/use_cases/sales/reasons_sales_mobile.svg'
import TriangleSales from '../../static/img/use_cases/sales/triangleReasons_sales.svg'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #F2F2F2;
`

const Title = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #333333;
`
const Desc = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #696973;
  padding-right: 50px;
  margin-top: 10px;
`

const Separator = styled(Flex)`
  width: 100%;
  height: 0px;
  border: 1px solid #e8daff;
  @media (max-width: 512px) {
    border: 0.5px solid #e8daff;
  }
`

const Percentage = styled.h3`
  font-size: 64px;
  font-weight: bold;
  line-height: 94px;
  letter-spacing: -0.04em;
  color: #6f16ff;
  margin-bottom: -12px;
  margin-right: 7px;
`
const PercentageTitle = styled.h4`
  color: #6f16ff;
  font-size: 16px;
  font-weight: normal;
  line-height: 120%;
  margin-bottom: -23px;
  text-transform: none;
`
const PercentageSubtitle = styled.h4`
  color: #696973;
  font-size: 16px;
  font-weight: normal;
  line-height: 23px;
  margin-bottom: 20px;
  text-transform: none;
`

const ReasonPercentage = props => {
  if (props.template != 'UseCaseSales') {
    let separatorWidth = 1
    let titleWidth = [1, 1]
    let subtitleWidth = [1, 1]

    if (props.template == 'UseCaseCustomerService') {
      titleWidth = [0.5, 0.25]
      separatorWidth = 0.65
    }
    if (props.template == 'UseCaseMarketing') {
      titleWidth = [0.5, 0.3]
      subtitleWidth = [1, 0.8]
    }
    return (
      <Flex
        flexDirection="column"
        width={1}
        mt={[-30, 0]}
        pt={[0, 150]}
        pl={[0, 110]}
      >
        <Flex width={[1, separatorWidth]}>
          <Separator style={{ marginBottom: 10 }} />
        </Flex>
        <Flex flexDirection="column">
          <Flex alignItems="center">
            <Percentage>{props.item.percentage}</Percentage>
            <Flex width={titleWidth}>
              <PercentageTitle>{props.item.title}</PercentageTitle>
            </Flex>
          </Flex>
          <Flex width={subtitleWidth}>
            <PercentageSubtitle>{props.item.subtitle}</PercentageSubtitle>
          </Flex>
        </Flex>
        <Flex width={[1, separatorWidth]}>
          <Separator style={{ marginTop: 10 }} />
        </Flex>
      </Flex>
    )
  } else {
    return <></>
  }
}

const Reasons = props => {
  let paddingBottom = 95
  let paddingBottomMobile = 150
  let paddingTopMobile = 180
  let width = '130%'
  if (props.pageContext.template == 'UseCaseSales') {
    paddingBottom = 250
    paddingBottomMobile = 350
    width = 0.7
    paddingTopMobile = 10
  }
  if (
    props.pageContext.template == 'UseCaseMarketing' ||
    props.pageContext.template == 'UseCaseCustomerService'
  ) {
    paddingTopMobile = 200
  }
  return (
    <>
      <Section px={[15, 0]} background={<Bg />}>
        <Flex
          pt={[paddingTopMobile, 70]}
          pb={[paddingBottomMobile, paddingBottom]}
          flexDirection={['column', 'row']}
        >
          {props.pageContext.template == 'UseCaseSales' && (
            <>
              <DesktopFlex>
                <ReasonsImgSales />
                <TriangleSales
                  style={{
                    position: 'absolute',
                    top: -41,
                    left: '-1%',
                    zIndex: -1,
                  }}
                />
              </DesktopFlex>
              <MobileFlex>
                <ReasonsImgSalesMobile />
              </MobileFlex>
            </>
          )}

          {props.pageContext.template != 'UseCaseSales' && (
            <>
              <Flex flexDirection="column" width={[1, width]}>
                <Flex width={[1, 434]}>
                  <Title>{props.section.title}</Title>
                </Flex>
                <Desc
                  dangerouslySetInnerHTML={{
                    __html: props.section.description,
                  }}
                />
              </Flex>

              <ReasonPercentage
                template={props.pageContext.template}
                item={props.section.reason_percentage}
              />
            </>
          )}

          {props.pageContext.template == 'UseCaseSales' && (
            <Flex pl={[0, 150]} flexDirection="column" width={[1, 1]}>
              <Flex width={[1, 0.7]}>
                <Title>{props.section.title2}</Title>
              </Flex>
              <Desc
                dangerouslySetInnerHTML={{
                  __html: props.section.description2,
                }}
              />
            </Flex>
          )}
        </Flex>
      </Section>
    </>
  )
}

export default Reasons
