import React from 'react'
import { Flex, Box } from '@rebass/grid'
import PointsBgTriangle from '../../../static/img/use_cases/cs/PointsBgTriangle.svg'
import PointsTriangle from '../../../static/img/use_cases/cs/PointsTriangle.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'

const CustomerServiceFigures = props => {
  return (
    <DesktopFlex>
      <PointsTriangle style={{ position: 'absolute', left: 0, top: 450 }} />
      <PointsBgTriangle
        style={{ position: 'absolute', top: 150, right: '10%' }}
      />
    </DesktopFlex>
  )
}

export { CustomerServiceFigures }
