import React from 'react'
import { Flex, Box } from '@rebass/grid'
import Rectangle from '../../../static/img/use_cases/cs/Rectangle.svg'
import RectangleTriangle from '../../../static/img/use_cases/cs/RectangleTriangle.svg'
import RectangleMobile from '../../../static/img/use_cases/sales/RectangleSalesMobile.svg'
import RectangleTriangleMobile from '../../../static/img/use_cases/cs/RectangleTriangleMobile.svg'
import DescTriangle from '../../../static/img/use_cases/sales/DescTriangle.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'
import Img from 'gatsby-image'
//Copy Paste
const SalesFigures = props => {
  return (
    <>
      <DesktopFlex
        style={{
          position: 'absolute',
          top: 73,
          right: 0,
        }}
      >
        <Flex>
          {props.img && (
            <Img
              fluid={props.img.localFile.childImageSharp.fluid}
              style={{
                position: 'absolute',
                top: 97,
                left: -90,
                width: 542,
                height: 562,
                zIndex: 1,
              }}
            />
          )}
          <RectangleTriangle
            style={{
              position: 'absolute',
              zIndex: 1,
              width: 215.25,
              height: 175.66,
              left: 318,
              top: 182,
            }}
          />
          <Rectangle style={{ position: 'relative' }} />
        </Flex>
      </DesktopFlex>
    </>
  )
}

const SalesFiguresMobile = props => {
  let narrowScreen = '0px'
  if (typeof window !== 'undefined') {
    if (screen.width < 350) {
      narrowScreen = `100px`
    }
  }
  return (
    <MobileFlex
      style={{
        position: 'absolute',
        top: `calc(52% + ${narrowScreen})`,
        right: 0,
      }}
    >
      <Flex>
        {props.img && (
          <Img
            fluid={props.img.localFile.childImageSharp.fluid}
            style={{
              position: 'relative',
              zIndex: 2,
              width: 345,
              height: 562,
              right: 0,
              top: -60,
            }}
          />
        )}
        <RectangleTriangleMobile
          style={{
            position: 'absolute',
            top: 308,
            right: 0,
            zIndex: 1,
          }}
        />
        <RectangleMobile
          style={{ position: 'absolute', top: -50, right: 0, zIndex: 0 }}
        />
      </Flex>
    </MobileFlex>
  )
}

export { SalesFigures, SalesFiguresMobile }
