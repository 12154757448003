import React from 'react'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import SecondNumberRectangle from '../../../static/img/use_cases/marketing/SecondNumberRectangle.svg'
import SecondNumberRectangleMobile from '../../../static/img/use_cases/marketing/SecondNumberRectangleMobile.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'

const Percentage = styled.h3`
  font-weight: bold;
  font-size: 64px;
  line-height: 80px;
  letter-spacing: -0.04em;
  color: #ffffff;
`
const PercentageTitle = styled.h4`
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
  width: 30%;
  text-transform: none;
  @media (max-width: 512px) {
    width: 50%;
  }
`
const PercentageSubtitle = styled.h5`
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #cbaaff;
  width: 90%;
  @media (max-width: 512px) {
    width: 100%;
  }
`

const PercentageTagline = styled.h6`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ff547d;
`

const MarketingSecondNumber = props => {
  return (
    <Flex
      mt={[-150, 0]}
      mb={-140}
      width={1}
      css={{ position: 'relative', background: 'transparent' }}
      justifyContent={['center', 'flex-end']}
    >
      <DesktopFlex>
        <SecondNumberRectangle />
      </DesktopFlex>
      <MobileFlex>
        <SecondNumberRectangleMobile />
      </MobileFlex>
      <Flex
        width={[0.7, 415]}
        py={[0, 15]}
        px={[20, 40]}
        css={{ position: 'absolute' }}
      >
        <Flex flexDirection="column">
          <Flex css={{ marginBottom: -40 }} alignItems="center">
            <Percentage style={{ marginRight: 9 }}>
              {props.number.number}
            </Percentage>
            <PercentageTitle>{props.number.title}</PercentageTitle>
          </Flex>
          <PercentageSubtitle style={{ marginBottom: 10 }}>
            {props.number.subtitle}
          </PercentageSubtitle>
          <PercentageTagline>{props.number.tagline}</PercentageTagline>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default MarketingSecondNumber
