import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'

import IntegrationsTriangle from '../../static/img/use_cases/cs/IntegrationsTriangle.svg'
import IntegrationsTriangleMobile from '../../static/img/use_cases/cs/IntegrationsTriangleMobile.svg'
import SalesTriangle from '../../static/img/use_cases/sales/IntegrationsTriangle.svg'
import Section from '../Section'
import { DesktopFlex, MobileFlex } from '../breakpoints'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #2E203B;
`

const Title = styled.h3`
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
`

const Description = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #f2f2f2;
`

const Integrate = props => {
  const { integrations, integrations_mobile } = useStaticQuery(
    graphql`
      query {
        integrations: file(relativePath: { eq: "use_cases/Integrations.png" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        integrations_mobile: file(
          relativePath: { eq: "use_cases/IntegrationsMobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  return (
    <>
      <Section
        px={[15, 0]}
        background={
          <>
            <Bg>
              <DesktopFlex>
                <IntegrationsTriangle
                  style={{ position: 'absolute', top: -50, left: '35%' }}
                />
              </DesktopFlex>
              <MobileFlex>
                <IntegrationsTriangleMobile
                  style={{ position: 'absolute', top: '50%', right: 0 }}
                />
              </MobileFlex>
            </Bg>
          </>
        }
        pt={[70, 80]}
        pb={[80, 160]}
      >
        <Flex width={1}>
          <Flex width={[1, 0.3]} mr={[0, '15%']} flexDirection={'column'}>
            <Title>{props.section.title}</Title>
            <Description
              dangerouslySetInnerHTML={{ __html: props.section.description }}
            />
            <MobileFlex>
              <Img
                style={{
                  position: 'relative',
                  width: 390,
                  height: 240,
                  zIndex: 1,
                }}
                fluid={integrations_mobile.childImageSharp.fluid}
              />
            </MobileFlex>
          </Flex>
          <DesktopFlex>
            {props.pageContext.template === 'UseCaseSales' && (
              <SalesTriangle
                style={{ position: 'absolute', top: 398, left: '2%' }}
              />
            )}
            <Img
              style={{
                position: 'absolute',
                width: 491,
                height: 270,
                top: 40,
                right: '19%',
                zIndex: 1,
              }}
              fluid={integrations.childImageSharp.fluid}
            />
          </DesktopFlex>
        </Flex>
      </Section>
    </>
  )
}

export default Integrate
