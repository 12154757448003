import React from 'react'
import NumberStoryTriangle from '../../../static/img/use_cases/cs/NumberStoryTriangle.svg'
import NumberStoryTriangleMobile from '../../../static/img/use_cases/cs/NumberStoryTriangleMobile.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'

const CustomerServiceFigures = props => {
  return (
    <>
      <DesktopFlex></DesktopFlex>
    </>
  )
}

const CustomerServiceFiguresMobile = props => {
  return (
    <MobileFlex>
      <NumberStoryTriangleMobile
        style={{ position: 'absolute', top: '31%', right: 0 }}
      />
    </MobileFlex>
  )
}

export { CustomerServiceFigures, CustomerServiceFiguresMobile }
