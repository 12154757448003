import React from 'react'
import { Flex, Box } from '@rebass/grid'
import HubtypePointsBgTriangle from '../../../static/img/use_cases/marketing/HubtypePointsBgTriangle.svg'
import HubtypePointsTriangle from '../../../static/img/use_cases/marketing/hubtpyePointMktg.svg'
import HubtypePointsTriangleContact from '../../../static/img/use_cases/marketing/triangleMktngContact.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'

const MarketingFigures = props => {
  return (
    <DesktopFlex>
      <HubtypePointsTriangle
        style={{ position: 'absolute', left: '40%', top: 390 }}
      />
      <HubtypePointsBgTriangle
        style={{ position: 'absolute', bottom: '5%', left: '5%' }}
      />
      <HubtypePointsTriangleContact
        style={{ position: 'absolute', left: '37%', bottom: -1000 }}
      />
    </DesktopFlex>
  )
}

export { MarketingFigures }
