import React from 'react'
import NumberStoryTriangle from '../../../static/img/use_cases/cs/NumberStoryTriangle.svg'
import NumberStoryTriangleMobile from '../../../static/img/use_cases/cs/NumberStoryTriangleMobile.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'

const SalesFigures = props => {
  return (
    <>
      <DesktopFlex>
        <NumberStoryTriangle
          style={{ position: 'absolute', top: '66%', left: '25%' }}
        />
      </DesktopFlex>
    </>
  )
}

const SalesFiguresMobile = props => {
  return (
    <MobileFlex>
      <NumberStoryTriangleMobile
        style={{ position: 'absolute', top: '33%', right: 0 }}
      />
    </MobileFlex>
  )
}

export { SalesFigures, SalesFiguresMobile }
