import React from 'react'
import ItsTimeTriangle1 from '../../../static/img/use_cases/marketing/ItsTimeTriangle1.svg'
import ItsTimeTriangle2 from '../../../static/img/use_cases/marketing/ItsTimeTriangle2.svg'
import ItsTimeTriangle1Mobile from '../../../static/img/use_cases/marketing/ItsTimeTriangle1Mobile.svg'
import ItsTimeTriangle2Mobile from '../../../static/img/use_cases/marketing/ItsTimeTriangle2Mobile.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'

const MarketingFigures = props => {
  return (
    <>
      <DesktopFlex>
        <ItsTimeTriangle1
          style={{ position: 'absolute', top: '19%', right: '6%' }}
        />
        <ItsTimeTriangle2
          style={{ position: 'absolute', top: '87%', left: '35%' }}
        />
      </DesktopFlex>
    </>
  )
}

const MarketingFiguresMobile = props => {
  return (
    <MobileFlex>
      <ItsTimeTriangle1Mobile
        style={{ position: 'absolute', top: '5%', right: 0 }}
      />
      <ItsTimeTriangle2Mobile
        style={{ position: 'absolute', top: '95%', right: '25%' }}
      />
    </MobileFlex>
  )
}

export { MarketingFigures, MarketingFiguresMobile }
