import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'

import InformationCaseMobile from '../../../static/img/use_cases/cs/InformationCaseMobile.svg'
import SecondNumberRectangleMobile from '../../../static/img/use_cases/sales/RectangleSalesMobile.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'

const Percentage = styled.h3`
  font-weight: bold;
  font-size: 64px;
  line-height: 80px;
  letter-spacing: -0.04em;
  color: #ffffff;
`

const InformationTaglineMobile = styled.h5`
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #ffffff;
  position: absolute;
  z-index: 2;
  width: 80%;
  margin-top: 92%;
  margin-left: 10px;
`
const PercentageTitle = styled.h3`
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
  width: 105px;
  @media (max-width: 512px) {
    width: 50%;
  }
`
const PercentageSubtitle = styled.h4`
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #cbaaff;
  width: 260px;
  @media (max-width: 512px) {
    width: 100%;
  }
  text-transform: none;
`

const PercentageTagline = styled.h5`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ff547d;
`

const SalesSecondNumber = props => {
  const { rectangle } = useStaticQuery(
    graphql`
      query {
        rectangle: file(
          relativePath: { eq: "use_cases/marketing/Rectangle1.png" }
        ) {
          childImageSharp {
            fixed(width: 317, height: 206) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return (
    <Flex
      mt={[-370, 0]}
      mb={-140}
      width={1}
      css={{ position: 'relative', background: 'transparent' }}
      justifyContent={['center', 'flex-start']}
    >
      <DesktopFlex>
        <Img
          style={{ position: 'absolute', top: '-333px', left: '190px' }}
          fixed={rectangle.childImageSharp.fixed}
        />
      </DesktopFlex>
      <MobileFlex flexDirection="column">
        <SecondNumberRectangleMobile
          style={{ width: 277, marginLeft: 10, marginBottom: -30 }}
        />
        <InformationCaseMobile style={{ width: 277, marginLeft: 5 }} />
      </MobileFlex>
      <DesktopFlex
        width={[0.7, 305]}
        py={[0, 15]}
        px={[20, 40]}
        css={{ position: 'absolute', top: -320, left: 180 }}
      >
        <Flex flexDirection="column">
          <Flex css={{ marginBottom: -40 }} alignItems="center">
            <Percentage style={{ marginRight: 9 }}>
              {props.number.number}
            </Percentage>
            <PercentageTitle>{props.number.title}</PercentageTitle>
          </Flex>
          <PercentageSubtitle style={{ marginBottom: 10 }}>
            {props.number.subtitle}
          </PercentageSubtitle>
          <PercentageTagline>{props.number.tagline}</PercentageTagline>
        </Flex>
      </DesktopFlex>
      <MobileFlex
        width={[0.7, 0.3]}
        py={[0, 15]}
        px={[20, 40]}
        css={{ position: 'absolute' }}
        mt={35}
      >
        <Flex flexDirection="column">
          <Flex css={{ marginBottom: -40 }} alignItems="center">
            <Percentage style={{ marginRight: 9 }}>
              {props.number.number}
            </Percentage>
            <PercentageTitle>{props.number.title}</PercentageTitle>
          </Flex>
          <PercentageSubtitle style={{ marginBottom: 10 }}>
            {props.number.subtitle}
          </PercentageSubtitle>
          <PercentageTagline>{props.number.tagline}</PercentageTagline>
        </Flex>
        <Flex justifyContent="flex-end" flexDirection="row">
          <InformationTaglineMobile>
            {props.information.tagline}
          </InformationTaglineMobile>
        </Flex>
      </MobileFlex>
    </Flex>
  )
}

export default SalesSecondNumber
