import React from 'react'
import { Flex } from '@rebass/grid'
import styled from 'styled-components'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import Section from '../Section'
import RedArrow from '../../static/img/use_cases/cs/RedArrow.svg'
import {
  CustomerServiceFigures,
  CustomerServiceFiguresMobile,
} from './cs/its_time_figures'
import { MarketingFigures } from './marketing/its_time_figures'
import { SalesFigures, SalesFiguresMobile } from './sales/its_time_figures'
import { langs } from '../../langs'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: ${props =>
    props.template == 'UseCaseMarketing' ? '#2E203B' : '#362745'};
`

const Title = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
`

const Description = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #cbaaff;

  @media (min-width: 512px) {
    width: 537px;
  }
`

const CTATitle = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 132.19%;
  color: #ff547d;
`

const CTA = styled.a`
  cursor: pointer;
  text-decoration: none;
`

const StyledRedArrow = styled(RedArrow)`
  @media (max-width: 512px) {
    height: 10%;
    width: 10%;
  }
`

const ItsTime = props => {
  return (
    <>
      <Section
        px={[15, 0]}
        background={<Bg template={props.pageContext.template} />}
        pt={[70, 90]}
        pb={[54, 90]}
      >
        <Flex flexDirection={['column', 'row']}>
          <Flex width={[1, 0.45]} pr={[0, 125]}>
            <Title>{props.section.title}</Title>
          </Flex>
          <Flex width={[1, 0.55]} flexDirection="column">
            <Description
              dangerouslySetInnerHTML={{ __html: props.section.description }}
            />
            <CTA
              href={`${langs[props.pageContext.lang]}/${props.section.cta.url}`}
            >
              {/* <Flex alignItems="flex-start">
                <StyledRedArrow
                  style={{
                    marginTop: 5,
                    marginRight: 10,
                  }}
                />
                <CTATitle>{props.section.cta.text}</CTATitle>
              </Flex> */}
            </CTA>
          </Flex>
        </Flex>
        <DesktopFlex>
          {props.pageContext.template === 'UseCaseCustomerService' && (
            <CustomerServiceFigures />
          )}
          {props.pageContext.template === 'UseCaseMarketing' && (
            <MarketingFigures />
          )}
          {props.pageContext.template === 'UseCaseSales' && <SalesFigures />}
        </DesktopFlex>
        <MobileFlex>
          {props.pageContext.template === 'UseCaseCustomerService' && (
            <CustomerServiceFiguresMobile />
          )}
          {props.pageContext.template === 'UseCaseSales' && (
            <SalesFiguresMobile />
          )}
        </MobileFlex>
      </Section>
    </>
  )
}

export default ItsTime
