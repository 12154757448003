import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import CasePointsRectangle from '../../../static/img/use_cases/cs/CasePointsRectangle.svg'
import Triangle from '../../../static/img/use_cases/cs/triangleContactCS.svg'
import { DesktopFlex } from '../../breakpoints'

const Percentage = styled.h3`
  font-weight: bold;
  font-size: 64px;
  line-height: 94px;
  letter-spacing: -0.04em;
  color: #ffffff;
`
const PercentageTitle = styled.h4`
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
  text-transform: none;
`
const PercentageSubtitle = styled.h5`
  font-size: 16px;
  line-height: 23px;
  color: #cbaaff;
`

const PercentageTagline = styled.h6`
  font-size: 12px;
  line-height: 16px;
  color: #ff547d;
`

const CustomerServiceNumber = props => {
  return (
    <Flex
      mt={[-100, 0]}
      mb={-140}
      width={1}
      css={{ position: 'relative', background: 'transparent' }}
      justifyContent={['center', 'flex-end']}
      pr={[0, '10%']}
    >
      <DesktopFlex>
        <Triangle style={{ position: 'absolute', left: 750 }} />
      </DesktopFlex>
      <CasePointsRectangle />
      <Flex width={[0.8, 275]} py={20} px={30} css={{ position: 'absolute' }}>
        <Flex flexDirection="column">
          <Flex css={{ marginBottom: -40 }} alignItems="center">
            <Percentage style={{ marginRight: 9 }}>
              {props.number.number}
            </Percentage>
            <PercentageTitle>{props.number.title}</PercentageTitle>
          </Flex>
          <PercentageSubtitle style={{ marginBottom: 10 }}>
            {props.number.subtitle}
          </PercentageSubtitle>
          <PercentageTagline>{props.number.tagline}</PercentageTagline>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CustomerServiceNumber
