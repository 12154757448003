import React from 'react'
import { Flex } from '@rebass/grid'
import styled from 'styled-components'
import Section from '../Section'
import CPoint1 from '../../static/img/use_cases/cs/Point1.svg'
import CPoint2 from '../../static/img/use_cases/cs/Point2.svg'
import CPoint3 from '../../static/img/use_cases/cs/Point3.svg'
import CPoint4 from '../../static/img/use_cases/cs/Point4.svg'
import CPoint5 from '../../static/img/use_cases/cs/Point5.svg'
import CPoint6 from '../../static/img/use_cases/cs/Point6.svg'
import MPoint1 from '../../static/img/use_cases/marketing/Point1.svg'
import MPoint2 from '../../static/img/use_cases/marketing/Point2.svg'
import MPoint3 from '../../static/img/use_cases/marketing/Point3.svg'
import MPoint4 from '../../static/img/use_cases/marketing/Point4.svg'
import MPoint5 from '../../static/img/use_cases/marketing/Point5.svg'
import MPoint6 from '../../static/img/use_cases/marketing/Point6.svg'

import SPoint1 from '../../static/img/use_cases/sales/Point1.svg'
import SPoint2 from '../../static/img/use_cases/sales/Point2.svg'
import SPoint3 from '../../static/img/use_cases/sales/Point3.svg'
import SPoint4 from '../../static/img/use_cases/sales/Point4.svg'
import SPoint5 from '../../static/img/use_cases/sales/Point5.svg'
import SPoint6 from '../../static/img/use_cases/sales/Point6.svg'
import { CustomerServiceFigures } from './cs/hubtype_points_figures'
import { MarketingFigures } from './marketing/hubtype_points_figures'
import { SalesFigures } from './sales/hubtype_points_figures'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #2E203B;
`

const Tagline = styled.h2`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  color: #ffffff;
  width: 40%;
  @media (max-width: 512px) {
    width: 100%;
  }
`

const PointTitle = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  padding-top: 18px;
  margin-bottom: 0px;
`

const Desc = styled.h4`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #cbaaff;
`

const Point = props => (
  <Flex mb={[-10, 0]} width={[1, 0.3]} alignItems="center">
    <Flex width={30} mr={15}>
      {props.point.icon}
    </Flex>
    <Flex pr={55} flexDirection="column">
      <Flex>
        <PointTitle>{props.point.title}</PointTitle>
      </Flex>
      <Flex pl={20} width={300}>
        <Desc>{props.point.description}</Desc>
      </Flex>
    </Flex>
  </Flex>
)

const HubtypePoints = props => {
  let points = props.section.points
  // TODO: retrieve icons from WP, not working
  let desktopWidth = 1
  let sectionPadding = 158
  let paddingTopDesktop = 0
  if (props.pageContext.template === 'UseCaseCustomerService') {
    desktopWidth = 0.9
    Object.assign(points.point_1, { icon: <CPoint1 /> })
    Object.assign(points.point_2, { icon: <CPoint2 /> })
    Object.assign(points.point_3, { icon: <CPoint3 /> })
    Object.assign(points.point_4, { icon: <CPoint4 /> })
    Object.assign(points.point_5, { icon: <CPoint5 /> })
    Object.assign(points.point_6, { icon: <CPoint6 /> })
  }
  if (props.pageContext.template === 'UseCaseMarketing') {
    desktopWidth = 0.9
    // TODO: Add corresponding icons for MarketingUseCase and same for SalesUseCase
    Object.assign(points.point_1, { icon: <MPoint1 /> })
    Object.assign(points.point_2, { icon: <MPoint2 /> })
    Object.assign(points.point_3, { icon: <MPoint3 /> })
    Object.assign(points.point_4, { icon: <MPoint4 /> })
    Object.assign(points.point_5, { icon: <MPoint5 /> })
    Object.assign(points.point_6, { icon: <MPoint6 /> })
  }
  if (props.pageContext.template === 'UseCaseSales') {
    sectionPadding = 190
    paddingTopDesktop = 50
    desktopWidth = 0.8
    // TODO: Add corresponding icons for MarketingUseCase and same for SalesUseCase
    Object.assign(points.point_1, { icon: <SPoint1 /> })
    Object.assign(points.point_2, { icon: <SPoint2 /> })
    Object.assign(points.point_3, { icon: <SPoint3 /> })
    Object.assign(points.point_4, { icon: <SPoint4 /> })
    Object.assign(points.point_5, { icon: <SPoint5 /> })
    Object.assign(points.point_6, { icon: <SPoint6 /> })
  }

  return (
    <>
      <Section px={[15, 0]} background={<Bg />} pt={[20, 73]}>
        <Flex
          pt={[30, paddingTopDesktop]}
          pb={[160, sectionPadding]}
          flexDirection={['column', 'row']}
        >
          <Flex flexDirection="column" pb={[40, 0]}>
            <Tagline>{props.section.tagline}</Tagline>
            <Flex
              width={[1, desktopWidth]}
              flexWrap="wrap"
              justifyContent="space-between"
            >
              {Object.entries(points).map(([k, point], i) => (
                <Point key={i} point={point} />
              ))}
            </Flex>
          </Flex>
        </Flex>
        {props.pageContext.template === 'UseCaseCustomerService' && (
          <CustomerServiceFigures />
        )}
        {props.pageContext.template === 'UseCaseMarketing' && (
          <MarketingFigures />
        )}
        {props.pageContext.template === 'UseCaseSales' && <SalesFigures />}
      </Section>
    </>
  )
}

export default HubtypePoints
