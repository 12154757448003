import React from 'react'
import { Flex, Box } from '@rebass/grid'
import PointsTriangle from '../../../static/img/use_cases/sales/firstTriangleSales.svg'
import PointsBgTriangle from '../../../static/img/use_cases/sales/PointsTriangle.svg'
import PointsTriangle2 from '../../../static/img/use_cases/sales/PointsTriangle2.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'

const SalesFigures = props => {
  return (
    <DesktopFlex>
      <PointsBgTriangle style={{ position: 'absolute', top: 118, right: 0 }} />
      <PointsTriangle2
        style={{ position: 'absolute', bottom: -40, right: '71%' }}
      />
    </DesktopFlex>
  )
}

export { SalesFigures }
