import React from 'react'
import { Flex, Box } from '@rebass/grid'
import Rectangle from '../../../static/img/use_cases/marketing/Rectangle.svg'
import RectangleTriangles from '../../../static/img/use_cases/marketing/RectangleTriangles.svg'
import RectangleMobile from '../../../static/img/use_cases/marketing/RectangleMobile.svg'
import RectangleTriangleMobile from '../../../static/img/use_cases/marketing/RectangleTriangleMobile.svg'
import DescBgTriangles from '../../../static/img/use_cases/marketing/DescBgTriangles.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'
import Img from 'gatsby-image'

const MarketingFigures = props => {
  return (
    <>
      <DesktopFlex>
        <DescBgTriangles
          style={{
            position: 'absolute',
            top: 200,
            zIndex: 2,
            left: '44%',
          }}
        />
      </DesktopFlex>
      <DesktopFlex
        style={{
          position: 'absolute',
          top: 73,
          right: 0,
        }}
      >
        <Flex>
          {props.img && (
            <Img
              fluid={props.img.localFile.childImageSharp.fluid}
              style={{
                position: 'absolute',
                top: 97,
                left: -90,
                width: 330,
                height: 560,
                zIndex: 1,
              }}
            />
          )}
          <RectangleTriangles
            style={{
              position: 'absolute',
              zIndex: 1,
              right: 0,
              top: '19%',
            }}
          />
          <Rectangle style={{ position: 'relative' }} />
        </Flex>
      </DesktopFlex>
    </>
  )
}

const MarketingFiguresMobile = props => {
  let narrowScreen = '0px'
  if (typeof window !== 'undefined') {
    if (screen.width < 350) {
      narrowScreen = `100px`
    }
  }
  return (
    <MobileFlex
      style={{
        position: 'absolute',
        top: `calc(52% + ${narrowScreen})`,
        right: 0,
      }}
    >
      <Flex>
        {props.img && (
          <Img
            fluid={props.img.localFile.childImageSharp.fluid}
            style={{
              position: 'relative',
              zIndex: 2,
              width: 330,
              height: 560,
              top: -60,
            }}
          />
        )}
        <RectangleTriangleMobile
          style={{
            position: 'absolute',
            top: 308,
            right: 0,
            zIndex: 1,
          }}
        />
        <RectangleMobile
          style={{ position: 'absolute', top: -50, right: 0, zIndex: 0 }}
        />
      </Flex>
    </MobileFlex>
  )
}

export { MarketingFigures, MarketingFiguresMobile }
