import React from 'react'
import NumberStoryBgTriangle from '../../../static/img/use_cases/marketing/NumberStoryBgTriangle.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'

const MarketingFigures = props => {
  return (
    <>
      <DesktopFlex></DesktopFlex>
    </>
  )
}

export { MarketingFigures }
