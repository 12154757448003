import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'

import { DesktopFlex } from '../breakpoints'
import Section from '../Section'
import Triangle1 from '../../static/img/use_cases/marketing/TrianglePurchase1.svg'
import Triangle2 from '../../static/img/use_cases/marketing/TriangleBottomMktng.svg'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #362745;
`

const Title = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
  text-transform: none;
  @media (min-width: 512px) {
    width: 410px;
    padding-bottom: 20px;
  }
`
const Subtitle = styled.h4`
  font-size: 22px;
  line-height: 32px;
  /* identical to box height */
  padding-top: 40px;
  @media (min-width: 512px) {
    width: 410px;
  }
  color: #ffffff;
  text-align: center;
`

const Percentatges = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 53px;
  text-align: center;

  color: #cbaaff;
`
const Text = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  width: 160px;
  color: #ffffff;
`

const Purchase = props => {
  const { icon1, icon2, icon3, icon4, icon5, icon6 } = useStaticQuery(
    graphql`
      query {
        icon1: file(relativePath: { eq: "use_cases/marketing/icon1.png" }) {
          childImageSharp {
            fixed(width: 70, height: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        icon2: file(relativePath: { eq: "use_cases/marketing/icon2.png" }) {
          childImageSharp {
            fixed(width: 70, height: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        icon3: file(relativePath: { eq: "use_cases/marketing/icon3.png" }) {
          childImageSharp {
            fixed(width: 70, height: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        icon4: file(relativePath: { eq: "use_cases/marketing/icon4.png" }) {
          childImageSharp {
            fixed(width: 50, height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        icon5: file(relativePath: { eq: "use_cases/marketing/icon5.png" }) {
          childImageSharp {
            fixed(width: 70, height: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        icon6: file(relativePath: { eq: "use_cases/marketing/icon6.png" }) {
          childImageSharp {
            fixed(width: 70, height: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  let { title, purchases } = props.section

  Object.assign(purchases.purchase1, {
    icon1: (
      <Img
        style={{ width: 52, height: 52 }}
        fixed={icon1.childImageSharp.fixed}
      />
    ),
    icon2: (
      <Img
        style={{ width: 52, height: 52 }}
        fixed={icon2.childImageSharp.fixed}
      />
    ),
  })
  Object.assign(purchases.purchase2, {
    icon1: (
      <Img
        style={{ width: 52, height: 52 }}
        fixed={icon3.childImageSharp.fixed}
      />
    ),
    icon2: <Img fixed={icon4.childImageSharp.fixed} />,
  })
  Object.assign(purchases.purchase3, {
    icon1: (
      <Img
        style={{ width: 52, height: 52 }}
        fixed={icon5.childImageSharp.fixed}
      />
    ),
    icon2: (
      <Img
        style={{ width: 52, height: 52 }}
        fixed={icon6.childImageSharp.fixed}
      />
    ),
  })

  return (
    <>
      <Section px={[15, 0]} background={<Bg />} pt={[200, 180]} pb={[80, 200]}>
        <DesktopFlex>
          <Triangle1 style={{ position: 'absolute', left: '38%', top: -94 }} />
          <Triangle2
            style={{ position: 'absolute', left: '40%', top: '286%' }}
          />
        </DesktopFlex>
        <Flex flexDirection="column">
          <Title>{title}</Title>
          <Flex flexDirection={['column', 'row']} ml={[0, -50]}>
            {Object.entries(purchases).map(([name, option]) => {
              return (
                <Flex
                  width={[1, 1 / 3]}
                  px={[0, 20]}
                  pt={20}
                  flexDirection="column"
                  key={name}
                >
                  <Subtitle>{option.title}</Subtitle>
                  <Flex
                    flexDirection="row"
                    pt={20}
                    style={{
                      borderTop: '1px solid #5D4477',
                    }}
                  >
                    <Flex pb={23} pt={20} justifyContent="center" width={1 / 2}>
                      {option.icon1}
                    </Flex>
                    <Flex pb={23} pt={20} justifyContent="center" width={1 / 2}>
                      {option.icon2}
                    </Flex>
                  </Flex>
                  <Flex>
                    <Flex justifyContent="center" width={1 / 2}>
                      <Percentatges>{option.percentage1}</Percentatges>
                    </Flex>
                    <Flex justifyContent="center" width={1 / 2}>
                      <Percentatges>{option.percentage2}</Percentatges>
                    </Flex>
                  </Flex>
                  <Flex
                    style={{
                      borderBottom: '1px solid #5D4477',
                    }}
                  >
                    <Flex pb={30} justifyContent="center" width={1 / 2}>
                      <Text>{option.text1}</Text>
                    </Flex>
                    <Flex pb={30} justifyContent="center" width={1 / 2}>
                      <Text>{option.text2}</Text>
                    </Flex>
                  </Flex>
                </Flex>
              )
            })}
          </Flex>
        </Flex>
      </Section>
    </>
  )
}

export default Purchase
