import React from 'react'
import { Flex } from '@rebass/grid'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import BlogPostCard from '../blog/blogPostCard'
import { useDownloadContentData } from '../../hooks/use-download-content-data'
import BlogPostsBgTriangle from '../../static/img/use_cases/BlogPostsBgTriangle.svg'
import BlogPostsBgTriangleMobile from '../../static/img/use_cases/BlogPostsBgTriangleMobile.svg'
import Section from '../Section'
import styled from 'styled-components'

const Title = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
`
const Subtitle = styled.h4`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #696973;
  text-transform: none;
`
const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #F2F2F2;
`

const PostsCarousel = props => {
  let { posts } = useDownloadContentData(props.pageContext.lang)
  const { edges: blogPosts } = posts
  const getTranslation = post =>
    post.polylang_translations.find(
      tr => tr.polylang_current_lang == props.pageContext.lang
    )
  let postsLang = blogPosts.filter(({ node: post }) => getTranslation(post))
  return (
    <Flex style={{ zIndex: 1 }} flexDirection="column">
      <Flex flexDirection={['column', 'row']} flexWrap="wrap">
        {postsLang.slice(0, props.items).map(({ node: post }, i) => {
          let p = getTranslation(post)
          return (
            p && (
              <BlogPostCard
                key={post.id}
                post={p}
                lang={props.pageContext.lang}
                width={1 / props.width}
              />
            )
          )
        })}
      </Flex>
    </Flex>
  )
}

const Posts = props => {
  return (
    <Section background={<Bg />} px={[15, 0]} pt={80} pb={80}>
      <Flex flexDirection="column" width={[1, 0.32]}>
        <Title style={{ marginBottom: 10 }}>{props.section.title}</Title>
        <Subtitle>{props.section.description}</Subtitle>
      </Flex>
      <PostsCarousel {...props} items={props.items} width={props.width} />
      <DesktopFlex>
        <BlogPostsBgTriangle
          style={{ position: 'absolute', top: 0, left: '70%' }}
        />
      </DesktopFlex>
      <MobileFlex>
        <BlogPostsBgTriangleMobile
          style={{ position: 'absolute', top: -60, left: '55%' }}
        />
      </MobileFlex>
    </Section>
  )
}

export default Posts
