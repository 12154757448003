import React from 'react'
import { Flex, Box } from '@rebass/grid'
import Section from '../Section'
import styled from 'styled-components'
import {
  CustomerServiceFigures,
  CustomerServiceFiguresMobile,
} from './cs/case_description_figures'
import {
  MarketingFigures,
  MarketingFiguresMobile,
} from './marketing/case_description_figures'
import {
  SalesFigures,
  SalesFiguresMobile,
} from './sales/case_description_figures'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #362745;
`

const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 52px;
  color: #ffffff;
`

const Tagline = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #e6dbff;
`

const Description = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #e6dbff;
`

const CaseDescription = props => {
  return (
    <>
      <Section px={[15, 0]} background={<Bg />} pt={73} pb={[180, 0]}>
        <Flex pt={[30, 77]} pb={['150%', 88]} flexDirection={['column', 'row']}>
          <Flex flexDirection="column" width={[1, 0.413]} pb={[40, 0]}>
            <Title>{props.section.title}</Title>
            <Tagline>{props.section.tagline}</Tagline>
            <Description
              dangerouslySetInnerHTML={{
                __html: props.section.description,
              }}
            />
          </Flex>
          {props.pageContext.template === 'UseCaseCustomerService' && (
            <CustomerServiceFigures img={props.section.img} />
          )}
          {props.pageContext.template === 'UseCaseMarketing' && (
            <MarketingFigures img={props.section.img} />
          )}
          {props.pageContext.template === 'UseCaseSales' && (
            <SalesFigures img={props.section.img} />
          )}
        </Flex>
        {props.pageContext.template === 'UseCaseCustomerService' && (
          <CustomerServiceFiguresMobile img={props.section.img_mobile} />
        )}
        {props.pageContext.template === 'UseCaseMarketing' && (
          <MarketingFiguresMobile img={props.section.img_mobile} />
        )}
        {props.pageContext.template === 'UseCaseSales' && (
          <SalesFiguresMobile img={props.section.img_mobile} />
        )}
      </Section>
    </>
  )
}

export default CaseDescription
