import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'

import FirstNumberRectangle from '../../../static/img/use_cases/marketing/FirstNumberRectangle.svg'
import { DesktopFlex, MobileFlex } from '../../breakpoints'

const Percentage = styled.h3`
  font-weight: bold;
  font-size: 64px;
  line-height: 94px;
  letter-spacing: -0.04em;
  color: #ffffff;
`
const PercentageTitle = styled.h3`
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
`
const PercentageSubtitle = styled.h4`
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #cbaaff;
  text-transform: none;
`

const PercentageTagline = styled.h5`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ff547d;
`

const MarketingFirstNumber = props => {
  const { rectangleMobile } = useStaticQuery(
    graphql`
      query {
        rectangleMobile: file(
          relativePath: { eq: "use_cases/marketing/rectangle_mobile_big.png" }
        ) {
          childImageSharp {
            fixed(width: 327, height: 228) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return (
    <Flex
      width={1}
      css={{ position: 'relative' }}
      justifyContent={['center', 'flex-end']}
      pr={[0, 150]}
      mb={[-50, 0]}
    >
      <DesktopFlex>
        <FirstNumberRectangle
          style={{ position: 'absolute', top: -140, right: 150 }}
        />
      </DesktopFlex>
      <MobileFlex>
        <Img
          style={{ position: 'absolute', top: '-135px', right: '15px' }}
          fixed={rectangleMobile.childImageSharp.fixed}
        />
      </MobileFlex>
      <Flex
        width={[0.9, 335]}
        py={20}
        px={30}
        css={{ position: 'absolute', top: -135 }}
      >
        <Flex flexDirection="column">
          <Flex css={{ marginBottom: -40 }} alignItems="center">
            <Percentage style={{ marginRight: 9 }}>
              {props.number.number}
            </Percentage>
            <PercentageTitle>{props.number.title}</PercentageTitle>
          </Flex>
          <PercentageSubtitle style={{ marginBottom: 10 }}>
            {props.number.subtitle}
          </PercentageSubtitle>
          <PercentageTagline>{props.number.tagline}</PercentageTagline>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default MarketingFirstNumber
