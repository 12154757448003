import React from 'react'
import { Flex } from '@rebass/grid'
import Navbar from '../Navbar'
import Footer from '../footer'
import Posts from './posts'

const UseCasesContainer = props => {
  return (
    <Flex flexDirection="column" css={{ overflow: 'hidden' }}>
      <Navbar
        theme="dark"
        style={{
          background: 'linear-gradient(180deg, #333333 0%, #6500CB 1266.15%)',
        }}
        {...props}
      />
      {props.children}
      <Posts {...props} items={4} width={4} />
      <Footer {...props} displayBefore={'none'} marginTop="-60px" />
    </Flex>
  )
}

export default UseCasesContainer
