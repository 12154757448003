import React from 'react'
import { Flex } from '@rebass/grid'
import styled from 'styled-components'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import Section from '../Section'
import { OutlineButton } from '../buttons'
import ContactTriangles from '../../static/img/use_cases/sales/ContactTrianglesSales.svg'
import ContactTrianglesMobile from '../../static/img/use_cases/cs/ContactTrianglesMobile.svg'
import ContactTriangleMobile1 from '../../static/img/use_cases/ContactTriangleMobile1.svg'
import ContactTriangleMobile2 from '../../static/img/use_cases/ContactTriangleMobile2.svg'
import { langs } from '../../langs'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #FF2B5E;
`

const Title = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 20px;
`

const Subtitle = styled.h4`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
`

const ContactBg = props => {
  let positionTopTriangle1 = 120
  let positionTopTriangle2 = 310
  if (props.useCase == 'UseCaseMarketing') {
    positionTopTriangle1 = 20
    positionTopTriangle2 = 196
  }
  return (
    <Bg>
      <DesktopFlex>
        <ContactTriangles
          style={{ position: 'absolute', right: props.atribute, top: -80 }}
        />
      </DesktopFlex>
      <MobileFlex>
        <ContactTriangleMobile1
          style={{
            position: 'absolute',
            top: positionTopTriangle1,
            left: '35%',
          }}
        />
        <ContactTriangleMobile2
          style={{
            position: 'absolute',
            top: positionTopTriangle2,
            left: '64%',
          }}
        />
      </MobileFlex>
    </Bg>
  )
}

const Contact = props => {
  let rightAttribute = 0
  let paddingTop = 200
  let paddingBottom = 75
  let useCase = props.pageContext.template
  if (props.pageContext.template == 'UseCaseSales') {
    rightAttribute = 130
  }
  if (props.pageContext.template == 'UseCaseMarketing') {
    paddingTop = 85
    paddingBottom = 40
  }
  return (
    <>
      <Section
        px={[15, 0]}
        background={<ContactBg atribute={rightAttribute} useCase={useCase} />}
        pt={[paddingTop, 58]}
        pb={[paddingBottom, 118]}
      >
        <Flex flexDirection="column" width={[1, 0.3]} pb={[40, 0]}>
          <Title>{props.section.title}</Title>
          {props.section.subtitle && (
            <Subtitle>{props.section.subtitle}</Subtitle>
          )}

          <OutlineButton
            color="white"
            hoverBackground="#ff4d76"
            borderColor="white"
            href={`${langs[props.pageContext.lang]}/${
              props.section.button.url
            }`}
            style={{ width: '50%' }}
          >
            {props.section.button.text}
          </OutlineButton>
        </Flex>
      </Section>
    </>
  )
}

export default Contact
