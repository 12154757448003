import React from 'react'
import { Flex, Box } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import Section from '../Section'
import styled from 'styled-components'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #362745;
`

const Title = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 10px;
  padding-right: 13px;
  padding-bottom: ${props => (props.template == 'UseCaseSales' ? '30px' : 0)};
`

const Desc = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  color: #e6dbff;
`

const ContactBg = props => (
  <Bg>
    <DesktopFlex />
    <MobileFlex />
  </Bg>
)

const Conversation = props => {
  let topImg = -10
  let paddingTopMobile = 65
  if (props.pageContext.template == 'UseCaseSales') {
    topImg = 90
  }
  const { conversation, conversation_mobile, rectangle } = useStaticQuery(
    graphql`
      query {
        conversation: file(
          relativePath: { eq: "use_cases/messageChannels.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        conversation_mobile: file(
          relativePath: { eq: "use_cases/messageChannelMobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  props.pageContext.template == 'UseCaseSales' ? (paddingTopMobile = 180) : ''

  return (
    <Section
      px={[15, 0]}
      background={<ContactBg />}
      pt={[paddingTopMobile, 90]}
      pb={[170, 90]}
    >
      <Flex width={1} flexDirection={['column', 'row']}>
        <Flex width={[1, 0.35]} flexDirection="column">
          <Title template={props.pageContext.template}>
            {props.info.title}
          </Title>
          {props.pageContext.template == 'UseCaseSales' && (
            <Desc
              dangerouslySetInnerHTML={{
                __html: props.info.description,
              }}
            />
          )}
        </Flex>
        <Flex pl={[0, 140]} width={[1, 0.6]}>
          <DesktopFlex>
            <Img
              style={{
                width: 500,
                height: 220,
                top: topImg,
                right: 0,
                zIndex: 1,
              }}
              fluid={conversation.childImageSharp.fluid}
            />
          </DesktopFlex>
          <MobileFlex>
            <Img
              style={{
                width: 325,
                height: 320,
                top: 71,
                zIndex: 1,
              }}
              fluid={conversation_mobile.childImageSharp.fluid}
            />
          </MobileFlex>
        </Flex>
      </Flex>
    </Section>
  )
}

export default Conversation
